/**
 * @constant specialCharactersRegex
 * @description Is a regex used to verify if have any special characters in a string.
 */
export const specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
export const anyOneSpecialCharactersRegex = /^(?=.*[~`!@#$%^&*()_\-+={[}\]|\:;"'<,>.?\/]).*.$/

// Characters that will be allowed on all the TextFields
export const alphaCharactersAllowed = /[^A-Za-z ]/g;
export const currencyAmountAllowed = /[^0-9^.]+$/g;
export const numbersAllowed = /[^0-9]+$/g;
export const emailCharactersAllowed = /[^A-Za-z0-9@._ -]/g;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const alphaNumericCharactersAllowed = /[^A-Za-z0-9, -]/g;
export const phoneNumberAllowed = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?(([0-9]| ){0,7})$/;
export const alphaCharactersWithNumbersAllowed = /[^A-Za-z0-9 ]/g;
export const alphaCharactersWithNumbersAndComaAllowed = /[^A-Za-z0-9, ]/g;
export const alphaCharactersWithNumbersAndDecimal = /[^A-Za-z0-9. ]/g;
export const remitbeeEmailNotAllowed = /^((?!remitbee).)*$/;
export const currencyMarkdownRegex = /({{[A-Za-z]{3}}})/;
export const currencyRegex = /[A-Za-z]{3}/;
export const alphaCharactersRegex = /^(?=.*[A-Z]).*.$/;
export const lowerCharactersRegex = /^(?=.*[a-z]).*.$/;
export const numCharactersRegex = /^(?=.*[0-9]).*.$/;


export const requireNonSpace = /.*\S.*/;
export const postalCode = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/
export const phoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const australiaPostalCode = /^[0-9]{4}$/;
export const bankAccount =/(^([a-zA-Z0-9]+)$)?(\d{1})?/;
export const bankAccountNumber =/^[0-9]{1,50}$/;
export const indianBankAccountNumber = /^[0-9]{1,50}$/;
export const debitCardCVVNumber = /^[0-9]{1,50}$/;
export const website = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
/**
 * @const relativePathRegex
 * @description It verifies if a relative path it has only one /.
 */
export const relativePathRegex = /^\/[^\/\\]/;
/**
 * @const relativePathRegex
 * @description It verifies if a string containing only numbers matches determined length /.
 */
export const numbersWithMinMaxLength = (minLength, maxLength) => new RegExp(`^[0-9]{${minLength},${maxLength}}$`);